import React, { useEffect, useState } from "react";
import styles from "./navbar.module.css";
// import mName from 'static/helping_hotels_logo_website.png';
import mNameWhite from "static/helpinghotels_white.png";
import mLogo from "static/helpinghotels_logo.png";
import { Link } from "react-router-dom";
import Modal from "./Modal/modal";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [navCol, setNavCol] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const onClick = () => {
    setClick(!click);
  };

  const onClickHandle = () => {
    if (click) setClick(false);
  };

  const goBack = () => {
    window.history.back();
  };

  const onModelClick = () => {
    setShowModel(!showModel);
  };

  const handleNavbar = () => {
    if (window.scrollY > 100) setNavCol(true);
    else setNavCol(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavbar);
  });
  // ${navCol ? `${styles.back}` : ''} ${navCol ? 'bg-light fixed-top animated fadeIn' : ''}
  return (
    <>
      <header
        className={`header_area animated fadeIn homenav ${
          navCol ? "navbar_fixed" : ""
        }`}
        onClick={onClickHandle}
      >
        <div className={`main_menu`}>
          <nav
            className={`navbar navbar-expand-lg navbar-light ${styles.navbar_height}`}
          >
            <div
              className={`container-fluid container-fluid-90 ${styles.navbar_height}`}
            >
              {/* <a
                className={`navbar-brand ${styles.name_margin} ${
                  navCol ? 'd-sm-block' : 'd-none'
                } ${styles.name_show}`}
                aria-label='logo'
                href='https://book.helpinghotels.com/'
              >
                <img src={mName} alt='logo' className={styles.helping_logo} />
              </a> */}

              <a
                className={`navbar-brand logo_h light_logo ${
                  styles.name_show
                } ${navCol ? "d-none" : ""} ${styles.logo_padding}`}
                aria-label="logo"
                href="https://book.helpinghotels.com/"
              >
                <img
                  src={mNameWhite}
                  alt="logo"
                  className={styles.helping_logo_2}
                />
              </a>

              {navCol && (
                <a
                  className={`navbar-brand logo_h ${styles.logo_show}`}
                  aria-label="logo"
                  href="https://book.helpinghotels.com/"
                >
                  <img
                    src={mLogo}
                    // src={navCol ? mLogo : mNameWhite}
                    alt="logo"
                    className={
                      navCol ? styles.helping_logo : styles.helping_logo_2
                    }
                  />
                </a>
              )}

              <a
                href="/"
                onClick={goBack}
                className="mob-back-btn d-block d-sm-none"
              >
                <i className="fas fa-chevron-left"></i>
              </a>

              {/* <!-- Trigger Button --> */}
              <Link
                to="#"
                aria-label="navbar"
                className="navbar-toggler mt-2"
                data-toggle="modal"
                data-target="#left_modal"
                onClick={onModelClick}
              >
                <span
                  className="icon-bar"
                  style={
                    navCol ? { background: "#000" } : { background: "#fff" }
                  }
                ></span>
                <span
                  className="icon-bar"
                  style={
                    navCol ? { background: "#000" } : { background: "#fff" }
                  }
                ></span>
                <span
                  className="icon-bar"
                  style={
                    navCol ? { background: "#000" } : { background: "#fff" }
                  }
                ></span>
              </Link>

              <div
                className="collapse navbar-collapse offset mt-1"
                id="navbarSupportedContent"
              >
                <div className="nav navbar-nav menu_nav justify-content-end">
                  <div className={`nav-item ${navCol ? "d-none" : ""}`}>
                    <a className="nav-link pt-4 mt-3" href="/">
                      <button
                        className={`btn ${styles.btn_link} ${styles.btn_active}`}
                      >
                        Home
                      </button>
                    </a>
                  </div>
                  <div className={`nav-item ${navCol ? "d-none" : ""}`}>
                    <a
                      className="nav-link pt-4 mt-3"
                      href="https://app.memberbutton.com"
                    >
                      <button className={`btn ${styles.btn_link}`}>
                        Influencer Stays
                      </button>
                    </a>
                  </div>
                  {/* <div className={`nav-item ${navCol ? 'd-none': ''}`}>
                                        <a className='nav-link pt-4 mt-3' href='https://experience.app.memberbutton.com/'>
                                            <button className={`btn ${styles.btn_link}`}> Experiences</button>
                                        </a>                                    
                                    </div> */}
                  <div className={`nav-item ${navCol ? "" : "d-none"}`}>
                    <a
                      className="nav-link pt-4 mt-3"
                      href="/"
                      aria-label="property-create"
                    >
                      <button className="btn button vbtn-default text-10 p-0 pl-4 pr-4 br-50">
                        <p className="p-2 mb-0 text-white"> Home</p>
                      </button>
                    </a>
                  </div>
                  <div className={`nav-item ${navCol ? "d" : "d-none"}`}>
                    <a
                      className="nav-link pt-4 mt-3"
                      href="https://app.memberbutton.com"
                      aria-label="property-create"
                    >
                      <button className="btn button vbtn-default text-10 p-0 pl-4 pr-4 br-50">
                        <p className="p-2 mb-0 text-white"> Influencer Stays</p>
                      </button>
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className="nav-link pt-4 mt-3"
                      href="https://chat.socialhub.center/memberbutton/channels/hotel-partner-onboarding"
                      aria-label="property-create"
                    >
                      <button className="btn button vbtn-default text-10 p-0 pl-4 pr-4 br-50">
                        <p className="p-2 mb-0 text-white">Add your Property</p>
                      </button>
                    </a>
                  </div>

                  {/* <div className="nav-item">
                                        <a className="nav-link globe pt-4 mt-4" href='/' aria-label="modalLanguge" data-toggle="modal" data-target="#languageModalCenter"> 
                                            <i className="fas fa-globe text-18" style={navCol ? {color: '#4F02A4'} : {}}></i> 
                                        </a>
                                    </div> */}

                  <div className="nav-item">
                    <div
                      className={`dropdown sv_user_login ${
                        click ? "show" : ""
                      } pt-4 mt-3`}
                      onClick={onClick}
                    >
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-bars" aria-hidden={click}></i>
                        <img
                          src="http://app.memberbutton.com/public/images/profile.jpg"
                          className="head_avatar"
                          alt=""
                        />
                      </button>

                      <ul className={`dropdown-menu ${click ? "show" : ""}`}>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/memberbutton-essence"
                          >
                            Book Stays
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/memberbutton-influencers-network"
                          >
                            Influencer & Referral Network
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="https://app.memberbutton.com/"
                          >
                            For Influencers
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/memberbutton-engine"
                          >
                            For Hotels
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/memberbutton-partnerships"
                          >
                            Partner With Us
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/about"
                          >
                            About
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label=""
                            data-toggle="modal"
                            className={styles.text_color}
                            data-target="#registermodel"
                            href="/policy"
                          >
                            Policy
                          </a>
                        </li>
                        {/* <li>
                                                    <a aria-label="" data-toggle="modal" className={styles.text_color} data-target="#loginmodel"  href="https://extranet.memberbutton.com/">
                                                        Extranet Login
                                                    </a>
                                                </li>                                         */}
                        <hr />
                        <li>
                          <Link
                            className={styles.text_color}
                            to="/faq"
                            target="_blank"
                          >
                            FAQ
                          </Link>
                        </li>
                        <hr />
                        <li>
                          <a
                            className={styles.text_color}
                            href="https://chat.socialhub.center/memberbutton/channels/hotel-partner-onboarding"
                          >
                            Chat & Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <Modal showModel={showModel} onModelClick={onModelClick} />
    </>
  );
};

export default Navbar;
