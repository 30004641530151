import React from "react";
import styles from './banner1.module.css'

const BannerOneText = () => {
    return (
        <div className={`container ${styles.margins}`}>
            <br/>
            <h4>
                The Essence of Helping Hotels
            </h4>

            <p>This ‘Guest’ alliance is a perfect fit between hotels, affiliations/referral networks, and shoppers. Helping Hotels as marketplace facilitates (hotel) ‘Stays’ and Experiences for everyone - all of them being considered direct bookings.</p>
            
            <br/>
              
            <div className='row'>
                <div className='col-sm'>
                    <h6>‘Guest’ can do / get:</h6>
                    <ul className={styles.list}>
                        <li>Hotels that subscribe to the booking engine/channel manager</li>
                        <li>Hotel’s special rates for stays</li>
                        <li>Customers that can become a guest of a particular hotel</li>
                        <li>The influencer affiliate/referral network</li>
                    </ul>
                </div>
                
                <br/>
                
                <div className='col-sm'>
                    <h6>The Button</h6>
                    <p>The ‘button’ represents the built-in zero commission booking engine that all hotels can use for their various needs, and link to for instance from their social media buttons.</p>
                <br/>
                
                </div>
            </div>

            <br/>
            <br/>

            <div className='row'>
                <p className='col-sm'>On the market, Helping Hotels is like no other service, bringing together all these members in very smart ways and giving win-win for all! It pairs up hotels with capable and dedicated lifestyle influencers, and also enables small and individual hotels to get their very own booking system to generate – increased – sales.</p>
                <hr/>
                <p className='col-sm'>Based on influencer marketing and social media influencers to promote hotel stays, Helping Hotel offers all hotels (small and independent as well as five stars) a new efficient way to facilitate bookings, and especially the selling of deals and promotions! </p>
                <hr/>
            </div>
            
                <br/>
                <br/>

            <div className='row'>
                <p className='col-sm'><span className={styles.span_text}>For direct bookings</span> the hotel uses this system entirely for free. Those only selling deals can add their campaign and update it, as and when they need it, by accessing the channel manager via Memberbutton’s extranet-based system.</p>
                <hr/>

                <p className='col-sm'><span className={styles.span_text}>Solving pain points;</span> accomplishing multi-matchmaking between hotels, influencers and other referrers and the consumers; and efficiently bringing deals and promotions out to the consumer with smart social media exposure and commission-based on sales only – these were all essential parts for designing Memberbutton – a true win-win concept.</p>
                <hr/>
            </div>
                <hr/>
        </div>
    )
}

export default BannerOneText;
