import React, { useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { BookingDataContext } from 'containers/data_context';

import routes from 'routing/routes';

export default function HeadManager() {
  const { t } = useTranslation();

  const { property } = useContext(BookingDataContext);
  const { data } = property;

  const isFaqPage = !!useRouteMatch(routes.faqPage);
  const isAboutPage = !!useRouteMatch(routes.aboutPage);
  const isHotelPage = !!useRouteMatch(routes.hotelPage);
  const isSearchPage = !!useRouteMatch(routes.searchPage);
  const isPolicyPage = !!useRouteMatch(routes.policyPage);
  const isPaymentPage = !!useRouteMatch(routes.paymentPage);
  const isBannerOnePage = !!useRouteMatch(routes.bannerOnePage);
  const isBannerTwoPage = !!useRouteMatch(routes.bannerTwoPage);
  const isBannerFourPage = !!useRouteMatch(routes.bannerFourPage);
  const isBannerFivePage = !!useRouteMatch(routes.bannerFivePage);
  const isBannerThreePage = !!useRouteMatch(routes.bannerThreePage);
  const isConfirmationPage = !!useRouteMatch(routes.confirmationPage);
  const isDefaultPage = !!useRouteMatch(routes.default);

  const getDocTitle = useCallback(() => {
    if (isSearchPage) {
      return t('global:search_page_doc_title');
    }

    if (isFaqPage) {
      return 'FAQ Helping Hotels';
    }

    if (isAboutPage) {
      return 'About Helping Hotels';
    }

    if (isPolicyPage) {
      return 'Privacy Policy';
    }

    if (isBannerOnePage) {
      return 'The Essence of Helping Hotels';
    }

    if (isBannerTwoPage) {
      return 'About Helping Hotels zero commission booking engine';
    }

    if (isBannerThreePage) {
      return 'Helping Hotels with Influencers';
    }

    if (isBannerFourPage) {
      return 'Helping Hotels for Influencers';
    }

    if (isBannerFivePage) {
      return 'Influencer & Hotel Brand Partnerships';
    }

    // if (isHotelPage) {
    //   const script = document.getElementById('head_script');
    //   if (script) {
    //     document.head.removeChild(script);
    //   }
    // }

    if (isHotelPage || isPaymentPage || isConfirmationPage) {
      return data?.title ?? t('global:loading');
    }
    if (isDefaultPage) {
      return '404';
    }

    return 'Helpinghotels.com |  Book unique hotels at direct hotel rates';
  }, [
    data,
    t,
    isConfirmationPage,
    isDefaultPage,
    isHotelPage,
    isPaymentPage,
    isSearchPage,
    isFaqPage,
    isPolicyPage,
    isAboutPage,
    isBannerOnePage,
    isBannerTwoPage,
    isBannerThreePage,
    isBannerFourPage,
    isBannerFivePage,
  ]);

  const docTitle = getDocTitle();
  const renderScripts = () => {
    const script = document.getElementById('head_script');
    if (script) {
      document.head.removeChild(script);
    }

    // const button = document.getElementById('boei_button');
    // if (button) {
    //   document.body.removeChild(button);
    // }

    if (isHotelPage) {
      return (
        <script
          src='https://app.boei.help/embed/k/1cb14e61-2070-4420-8171-9449c17790a4'
          async
          samesite='none'
          id='head_script'
        ></script>
      );
    }
    return (
      <script
        id='head_script'
        async
        defer
        src='https://cdn.boei.help/hello.js'
      ></script>
    );
  };

  return (
    <Helmet>
      <title>{docTitle}</title>
      <meta
        name='description'
        content='A Perfect-fit for hotels who want to get more reach when offering deals loved by customers and promoted by affiliates and referrers.'
      />
      {renderScripts()}
    </Helmet>
  );
}
