import React, { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Redirect, useHistory } from "react-router-dom";
// import axios from 'axios'

import BookinSummary from "components/booking_summary";
import Footer from "components/footer";
import Header from "components/header";
import SectionWrapper from "components/layout/section_wrapper";
import Loading from "components/loading";
import Navigation from "components/navigation";
import PaymentForm from "components/payment_form";
// import calculateSummaryParams from "utils/calculate_summary_params";

import {
  AppActionsContext,
  BookingActionsContext,
  BookingDataContext,
} from "containers/data_context";

import routes from "routing/routes";

import buildPath from "utils/build_path";

export default function PaymentPage() {
  const { property, params, roomsInfo, channelId } =
    useContext(BookingDataContext);
  const bookingActions = useContext(BookingActionsContext);
  const { init } = useContext(AppActionsContext);
  const history = useHistory();
  const { data: propertyData, isLoading: isPropertyLoading } = property;
  const { data: roomsData, isLoading: isRoomsLoading } = roomsInfo;
  const isPropertyPresent = propertyData && !isPropertyLoading;
  const isRoomsPresent = roomsData && !isRoomsLoading;
  // const { ratesOccupancyPerRoom, checkinDate, checkoutDate, currency } = params;
  const { ratesOccupancyPerRoom, checkinDate, checkoutDate } = params;
  const isReqiredDataPresent =
    ratesOccupancyPerRoom && checkinDate && checkoutDate;
  // const [Curr, setCurr] = useState()
  // const [total, setTotal] = useState(0)
  // const [roomID, setRoomID] = useState()
  // const [btcpay, setBtcpay] = useState()

  const onSuccess = (bookingParams) => {
    const { uniqueId: bookingId } = bookingParams;
    const routeParams = { channelId, bookingId };
    const { search } = history.location;
    const confirmationPagePath = buildPath(
      search,
      routes.confirmationPage,
      routeParams
    );
    history.push(confirmationPagePath);
  };

  // useEffect(() => {
  //   if(!roomsData)
  //     return;

  //   roomsData.forEach((room) => {
  //     if(room.id === roomID){
  //       setBtcpay(room.ratePlans[0].cancellationPolicy.guaranteePaymentPolicy)
  //     }
  //   })

  // }, [roomID, roomsData])

  // useEffect(
  //   function setSummaryParams() {
  //     const summaryParams = calculateSummaryParams(roomsData, ratesOccupancyPerRoom);

  //     if (!summaryParams) {
  //       return;
  //     }

  //     Object.keys(ratesOccupancyPerRoom).forEach((roomId) => {
  //       setRoomID(roomId)
  //     })

  //     setTotal(summaryParams.total)
  //   },
  //   [roomsData, ratesOccupancyPerRoom],
  // );

  // useEffect(() => {
  //   async function fetchData() {
  //     const result = await axios(`https://v6.exchangerate-api.com/v6/f367843ac962a12f9a288d59/latest/${currency}`);
  //     setCurr(result.data.conversion_rates.USD)
  //   }

  //   fetchData()
  // }, [currency, total])

  useEffect(
    function initApp() {
      const savedBookingParams = bookingActions.getDataFromStorage();

      init(bookingActions, savedBookingParams);
    },
    [bookingActions, init]
  );

  // useEffect(() => {
  //   if (channelId === '64b5f6da-a470-4ec9-83d7-6e57e086ea14') {
  //     const script = document.createElement('script');
  //     script.src =
  //       'https://app.boei.help/embed/k/1cb14e61-2070-4420-8171-9449c17790a4';
  //     script.async = true;

  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [channelId]);

  if (!isPropertyPresent || !isRoomsPresent) {
    return <Loading />;
  }

  if (!isReqiredDataPresent) {
    const redirectPath = buildPath("", routes.hotelPage, { channelId });

    return <Redirect to={redirectPath} />;
  }

  return (
    <div>
      <Header property={propertyData} />
      <SectionWrapper theme="dark">
        <Col xs="12" lg="8">
          <>
            <Navigation />
            <PaymentForm
              property={propertyData}
              params={params}
              rooms={roomsData}
              channelId={channelId}
              onSuccess={onSuccess}
              // USD={total * Curr}
              // btcpay={btcpay}
            />
          </>
        </Col>
        <Col xs="12" lg="4">
          <BookinSummary
            property={propertyData}
            rooms={roomsData}
            params={params}
          />
        </Col>
      </SectionWrapper>
      <Footer property={propertyData} />
    </div>
  );
}
