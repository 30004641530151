import React from 'react';

import styles from './banner3.module.css';

const Banner3 = () => {
  return (
    <>
      <div className={styles.banner2}>
        <div className={`row ${styles.celebrate}`}>
          <div className={`col-md-12 ${styles.celebrate_back}`}>
            <h2 className={`font-weight-400 mb-2 text-white`}>
              Influencer affiliation & referral network
            </h2>
            <p className='text-14 text-white'>
              Being integrated with our influencer marketing solution and search
              engine, hotels only pay commission on generated and completed
              bookings via the affiliations/referral network – and at lower cost
              than usually charged by OTAs, moreover hotels will be less
              dependent on OTA's when using the Helping Hotels platform.
            </p>
            <a href='/memberbutton-influencers-network'>
              <button className='p-3 rounded-4 border-0 font-weight-500 mt-5'>
                Learn More
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className={`${styles.banner2}`}>
        <div className={`row ${styles.back2}`}>
          <div className={`col-md-12 ${styles.celebrate_back}`}>
            <h2 className={`font-weight-400 mb-2 text-white`}>
              Helping Hotels influencers
            </h2>
            <p className='text-14 text-white'>
              All guests and stakeholders can meet, collaborate and benefit from
              simplicity, consumer-driven marketing participation and increased
              direct bookings (with win-win including lower costs for
              properties). As an influencer you can become part of our
              professional affiliations network. Some hotels will know the value
              of hosting an influencer at their property.
            </p>
            <a href='/memberbutton-influencers'>
              <button className='p-3 rounded-4 border-0 font-weight-500 mt-5'>
                Learn More
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner3;
