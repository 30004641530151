import React from "react";
import { Accordion, Card } from "react-bootstrap";

import FAQ from "utils/Faqs/faq_s";

import './faq.css'

const Faq = () => {

  const renderAccordion = (faq, index) => {
    return (
      <Accordion key={index}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={faq}>
            {faq[0]} <i>+</i>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={faq}>
            <Card.Body>

                {faq.map((item, index) => {
                  return <p>{index !== 0 ? item : ''}</p>
                })}
            
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    ); 
  };


  return (
        <div className='container Faq'>
            <div className='heading'> 
                <h4 className='head_color'>Frequetly Asked Questions</h4>
            </div>
            {FAQ.map(renderAccordion)}
        </div>
    );
};

export default Faq;
