import React from "react";

import Navbar from "components/Nabar";
// import Slider from 'components/slider'
import Faq from "components/faq";
import Footer from "components/home_footer";
import first from "static/first.jpg";

import styles from "./faq_page.module.css";

const FaqPage = () => {
  return (
    <div>
      <Navbar />

      <div className={styles.image}>
        <img
          src={first}
          alt="this is alternative name of pic"
          className={styles.innerimage}
        />
      </div>

      <div className={styles.margin}>
        <Faq />
      </div>

      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default FaqPage;
