import React from "react";
import Item from "./Item/Item";
import styles from "./../navbar.module.css";

const Modal = ({ showModel, onModelClick }) => {
  return (
    <div
      className={`modal fade right ${showModel ? `show ${styles.model}` : ""}`}
      id="left_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="left_modal"
      arial-model={`${showModel}`}
      arial-hidden={`${showModel}`}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 secondary-bg">
            <button
              type="button"
              className="close text-28"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={onModelClick}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body p-0">
            <ul className="mobile-side">
              <Item Link="https://memberbutton.com" title="Home" />
              <Item
                Link="https://experience.app.memberbutton.com/"
                title="Experiences"
              />
              <Item Link="/memberbutton-influencers" title="For Influencers" />
              <Item
                Link="/memberbutton-influencers-network"
                title="Influencer & Referral Network"
              />
              <Item Link="/memberbutton-essence" title="Book Stays" />
              <Item Link="/memberbutton-engine" title="For Hotels" />
              <Item Link="/about" title="About" />
              <Item Link="/policy" title="Policies" />
              {/* <Item Link='https://experience.app.memberbutton.com/' title='Experiences'/> */}
              {/* <Item Link='https://extranet.memberbutton.com/sign-up' title='Sign Up'/> */}
              {/* <Item Link='https://extranet.memberbutton.com/' title='Log In'/> */}
              <Item
                Link="https://chat.socialhub.center/memberbutton/channels/hotel-partner-onboarding"
                title="Chat & Support"
              />

              <li>
                <a className={styles.text_color} href="/faq">
                  FAQ
                </a>
              </li>
              <li>
                {/* <a href="https://memberbutton.com/" aria-label="modalLanguge" data-toggle="modal" data-target="#languageModalCenter">  */}
                <i className="fa fa-globe"></i> <u>English </u>
                {/* </a> */}
                {/* <a href="https://memberbutton.com/" aria-label="modalCurrency" data-toggle="modal" data-target="#currencyModalCenter">  */}
                <span className="ml-5">
                  &#36; - <u>THB</u>{" "}
                </span>
                {/* </a> */}
              </li>
              <a className="mt-3" href="/memberbutton-partnerships">
                <button className="btn vbtn-outline-success text-12 font-weight-500 pl-5 pr-5 pt-3 pb-3">
                  Partner With Us
                </button>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
