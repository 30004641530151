import React from "react";
import styles from './banner5.module.css'

const BannerFiveText = () => {
    return (
         <div className={`container ${styles.margins}`}>
            <br/>
            <h4>Influencer & Hotel Brand Partnerships</h4>

            <p>Helping Hotels will also initiate – and is open for – partner constellations such as dedicated marketing of local clusters of hotels or brands, where hotels and a selection of our influencers and affiliates are paired up.</p>
 
            
            <hr/>
            <br/>
 
            <div className='row'>
                <div className='col-sm'>
                    <p>In such cases a dedicated campaign with the select hotels will be directly promoted, including a direct link to the dedicated page of each participating hotel or a common campaign with (landing) page that influencers and/or other involved affiliates can share and earn from commission-generating bookings referrals.</p>
                </div>
                
                <br/>
                
                <div className='col-sm'>
                    <p>In this way hotels can raise awareness about a certain destination, promote the green season or other deal periods they may have or want to create. </p>
                <br/>
                
                </div>
            </div>

            <br/>
            <hr/>
            <br/>

            <div className='row'>
                <p> One of our Helping Hotels-specific opportunities will then be to have a touring marketing team of influencers to visit the participating hotels, to do live marketing and post content marketing, including audiovisual content with "Instagram Live",  "Facebook Live"  or other "Live" Social Media broadcasts."</p>
                
                <hr/>
            </div>
                <hr/>
        </div>
    )
}

export default BannerFiveText;
